<template>
  <div>
    <tab-bar-wrapper @newRow="newBreakDown()"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :componentName="$options.name"
        :columns="columns"
        :query="query"
        :filter="filter"
        :annotations="annotations"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :context-menu-items="contextMenuItems"
        :nested-grid="true"
        :nested-columns="nestedColumns"
        :nested-context-menu-items="nestedContextMenuItems"
        :is-row-master-function="rowMasterFunction"
        @ready="gridReady"
        @nestedReady="nestedGridReady"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <v-dialog
      v-model="localDialog"
      lazy
      persistent
      width="400px"
    >
      <v-card>
         <v-card-title class="blue lighten-2">
          <h3 class="white--text">Assign Break Down Task To:</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row justify-center align-center>
              <v-flex shrink>
                <users-list
                  :userId="assigneeID"
                  @updated="usersEventHandler"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <cancel-button @click="localDialog = false"/>
          <submit-button :disabled="!assigneeID" :loading="assigning" @click="assignBreakDown"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tabBarWrapper from '@/components/wrappers/tabBarWrapper'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
import gridWrapper from '@/components/wrappers/gridWrapper'
import grid from '@/components/mixins/grid'
import columns from '@/components/mixins/columns'
import {
  ASSIGN_BREAK_DOWN,
  GET_BREAK_DOWN_ITEMS,
  GET_BREAK_DOWNS, GRID__GET_BREAK_DOWN_ITEMS,
  UPDATE_BREAK_DOWN_ITEM_STATUS,
  UPDATE_BREAK_DOWN_STATUS
} from '@/api/graphql/Constants/Disassembly'
import users from '@/components/autocompletes/users'
import { mapActions } from 'vuex'
import { COLUMNS__BREAK_DOWN, COLUMNS__BREAK_DOWN_NESTED } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__BREAK_DOWN, CONTEXT_MENU__BREAK_DOWN_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__BREAK_DOWN, KB_SHORTCUT__BREAK_DOWN_NESTED } from '@/lib/agGridKbShortCuts'
import { BREAKDOWN_ITEM_STATUS, BREAKDOWN_STATUS } from '@/models/ExtraBackendModels'
import { REMOVE_SYSTEM_ADD_ON_ITEMS } from '@/api/graphql/Constants/Inventory'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'BreakDown',
  mixins: [grid, columns],
  components: {
    'tab-bar-wrapper': tabBarWrapper,
    'grid-wrapper': gridWrapper,
    'users-list': users,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  data () {
    return {
      contextMenuItems: params => CONTEXT_MENU__BREAK_DOWN(params, this.contextMenuCallback),

      nestedContextMenuItems: params => CONTEXT_MENU__BREAK_DOWN_NESTED(params, this.contextMenuCallback),

      gridApi: null,
      columnApi: null,
      nestedGridApi: null,
      nestedColumnApi: null,
      nestedRowData: [],

      query: GET_BREAK_DOWNS,
      filter: [],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'bd_items'
        }
      ],

      nestedQuery: GRID__GET_BREAK_DOWN_ITEMS,
      nestedFilters: (id) => [{ key: 'break_down__id', value: id }],
      nestedAnnotations: [],

      columns: COLUMNS__BREAK_DOWN,
      nestedColumns: COLUMNS__BREAK_DOWN_NESTED,

      localDialog: false,
      taskIdToAssign: 0,
      assigneeID: 0,
      assigning: false
    }
  },
  methods: {
    ...mapActions('notifications', {
      createSnackbar: 'createSnackbar'
    }),

    rowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    contextMenuCallback (params) {
      if (params.data && params.dataKey) this[params.dataKey] = params.data
      if (params.dialogToOpen) this.localDialog = params.dialogToOpen
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__BREAK_DOWN(params, this.contextMenuCallback), KB_SHORTCUT__BREAK_DOWN_NESTED(params, this.contextMenuCallback))
    },

    gridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi

      const sort = [{ colId: 'id', sort: 'desc' }]
      this.gridApi.setSortModel(sort)
    },

    nestedGridReady (params) {
      this.nestedGridApi = params.api
      this.nestedColumnApi = params.columnApi
    },

    completeBreakdown ({ id }) {
      this.$apollo.mutate({
        mutation: UPDATE_BREAK_DOWN_STATUS,
        variables: { id: id, status_id: 68 }
      }).then(async () => {
        try {
          const response = await this.$apollo.query({
            query: GET_BREAK_DOWN_ITEMS,
            variables: { id: id }
          })
          const items = response.data.breakDownItems
          const itemsToUpdate = []
          const addOnItemsToRemove = []
          for (const item of items) {
            itemsToUpdate.push({
              id: item.id,
              status_id: BREAKDOWN_ITEM_STATUS.COMPLETED
            })
            addOnItemsToRemove.push(item.id)
          }
          await this.$apollo.mutate({
            mutation: UPDATE_BREAK_DOWN_ITEM_STATUS,
            variables: { input: itemsToUpdate }
          })
          await this.$apollo.mutate({
            mutation: REMOVE_SYSTEM_ADD_ON_ITEMS,
            variables: { items: addOnItemsToRemove }
          })
          this.createSnackbar({
            message: 'Successfully completed break down.',
            color: 'success'
          })
        } catch {
          this.createSnackbar({
            message: 'Could not update break down items statuses.',
            color: 'error'
          })
        }
      }).catch(() => {
        this.createSnackbar({
          message: 'Could not update break down status.',
          color: 'error'
        })
      })
    },

    prepareBreakDownAssignment ({ params }) {
      this.taskIdToAssign = params.node.data.id
      this.assigneeID = params.node.data.assigned_to?.id ?? 0
      this.localDialog = true
    },

    assignBreakDown ({ user = this.assigneeID, id = this.taskIdToAssign }) {
      this.assigning = true
      this.$apollo.mutate({
        mutation: ASSIGN_BREAK_DOWN,
        variables: {
          id: id,
          assignee: user
        }
      }).then(({ data: { breakDown } }) => {
        this.localDialog = false
        this.createSnackbar({
          message: 'Assigned!',
          color: 'success',
          top: false,
          canClose: false,
          timeout: 4000
        })
      }).catch(() => {
        this.createSnackbar({
          message: 'An error has occurred and has been logged.',
          color: 'error',
          top: false,
          canClose: false,
          timeout: 4000
        })
      }).finally(() => { this.assigning = false })
    },

    breakDownIsReady (id) {
      this.createSnackbar({
        message: 'Marking the task as ToDo',
        color: 'info'
      })
      this.$apollo.mutate({
        mutation: UPDATE_BREAK_DOWN_STATUS,
        variables: {
          id: id,
          status_id: BREAKDOWN_STATUS.TO_BREAK_DOWN
        }
      }).then(() => {
        this.createSnackbar({
          message: 'Grabbing info for break down items.',
          color: 'info'
        })
        this.$apollo.query({
          query: GET_BREAK_DOWN_ITEMS,
          variables: { id: id }
        }).then(({ data: { breakDownItems } }) => {
          const itemsToUpdate = []
          this.createSnackbar({
            message: 'Marking the items to Break Down',
            color: 'info'
          })
          for (const item of breakDownItems) {
            if (Number(item.status.id) < Number(BREAKDOWN_ITEM_STATUS.TO_BREAK_OUT)) {
              itemsToUpdate.push({ id: item.id, status_id: BREAKDOWN_ITEM_STATUS.TO_BREAK_OUT })
            }
          }
          this.$apollo.mutate({
            mutation: UPDATE_BREAK_DOWN_ITEM_STATUS,
            variables: { input: itemsToUpdate }
          }).then(() => {
            this.$store.dispatch('notifications/createSnackbar', {
              message: 'Updated!',
              color: 'success'
            })
          }).catch(() => {
            this.$store.dispatch('notifications/createSnackbar', {
              message: 'Could not update items, but marked task.',
              color: 'error'
            })
          })
        })
      })
    },

    /**
     * Marks any selected break down items as the next status from which they are currently on i.e. status + 1
     * Auto cache update does not work with this function!
     * @param params
     * @param reset whether or not to reset them (for testing)
     */
    async markBreakDownItem ({ params, back = false }) {
      const nodes = params.api.getSelectedNodes()
      const items = []
      try {
        for (const node of nodes) {
          const nextStatus = back ? node.data.status.id - 1 : node.data.status.id + 1
          if ((!back && nextStatus <= 83) || (back && nextStatus >= 79)) {
            items.push({ id: node.data.id, status_id: nextStatus })
          }
        }
        await this.$apollo.mutate({
          mutation: UPDATE_BREAK_DOWN_ITEM_STATUS,
          variables: { input: items }
        })
        this.createSnackbar({
          message: 'Updated statuses!',
          color: 'success',
          timeout: 4000
        })
      } catch {
        this.createSnackbar({
          message: 'Could not update statuses of break down items.',
          color: 'error',
          timeout: 4000
        })
      }
    },

    goToBreakdown ({ params }) {
      this.$router.push({
        name: 'new-break-down',
        params: {
          id: params.node.data.id
        }
      })
    },

    newBreakDown () {
      this.$router.push({ name: 'new-break-down' })
    },

    usersEventHandler (event) {
      this.assigneeID = event.value
    }
  }
}
</script>
